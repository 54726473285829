import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { LanguageContext } from '../../../context/LanguageContext'
import ContentPreview from '../../ContentPreview'
import GridContainer from '../../GridContainer'
import RenderIf from '../../RenderIf'
import { Node__Community, Query, Paragraph__Communities_Listing } from '../../../types/generated'

export const ParagraphCommunitiesListingFields = graphql`
  fragment ParagraphCommunitiesListingFields on paragraph__communities_listing {
    __typename
    id
    field_title
  }
`

const ParagraphCommunityListing = (props: Paragraph__Communities_Listing) => {
  const { field_title: fieldTitle } = props
  const { langcode } = useContext(LanguageContext)
  const data = useStaticQuery<Query>(graphql`
    query CommunityListingParagraphQuery {
      allNodeCommunity(sort: { fields: title, order: ASC }) {
        nodes {
          ...NodeCommunityFields
        }
      }
    }
  `)

  const allNodeCommunityNodes = data?.allNodeCommunity?.nodes
  // @todo: remove any type
  const nodes = allNodeCommunityNodes?.filter(
    (community: Node__Community) => community.langcode === langcode,
  )

  return (
    <section className="max-w-7xl" aria-labelledby="contact-heading">
      <RenderIf condition={!!fieldTitle}>
        <h1 className="font-bold text-center text-3xl mb-24">{fieldTitle}</h1>
      </RenderIf>
      <GridContainer>
        {nodes.map((community: any) => (
          <ContentPreview key={community.id} content={community} />
        ))}
      </GridContainer>
    </section>
  )
}

export default ParagraphCommunityListing
